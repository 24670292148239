import { ENDPOINTS } from '@constants/endpoints.constants'

export class BuildingBlocksAPI {
  private Client

  constructor(Instance) {
    this.Client = Instance.Client
  }

  index = (params = {}) => {
    return this.Client.get(ENDPOINTS.BUILDING_BLOCKS.INDEX, params).then(
      response => response
    )
  }

  show = (id, params = {}) => {
    return this.Client.get(ENDPOINTS.BUILDING_BLOCKS.SHOW(id), params).then(
      response => response
    )
  }
}
