import {
  getBaseUrl,
  GlobalRef,
  isServer,
} from '@utilities/helpers/core.helpers'
import { env } from 'next-runtime-env'

// Get ENV variables
const _bridge_url_ = isServer
  ? env('BRIDGE_URL')
  : env('NEXT_PUBLIC_BRIDGE_URL')
const _wp_base_url_ = getBaseUrl()
const _cache_revalidate_ = 3600

export const API_CONFIG = {
  base: {
    url: `${_wp_base_url_}`, // base WP API url, immutable
    timeout: 1000 * 60,
    responseType: 'json',
    responseEncoding: 'utf8',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  },
  site: {
    url: `${_wp_base_url_}`, // base WP API url, mutable
    timeout: 1000 * 60,
    responseType: 'json',
    responseEncoding: 'utf8',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  },
  bridge: {
    url: `${_bridge_url_}`, // base Bridge API url, immutable
    timeout: 1000 * 60,
    responseType: 'json',
    responseEncoding: 'utf8',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  },
  options: {
    next: { revalidate: _cache_revalidate_ },
  },
}

export const getAPIConfig = () => {
  const RnxtAPIConfig = new GlobalRef('rnxt.api')

  if (!RnxtAPIConfig.value) {
    RnxtAPIConfig.value = API_CONFIG.base.url
  }

  if (!isServer) {
    const destinationBaseUrl = (window as any).base_url
    RnxtAPIConfig.value = destinationBaseUrl
  }

  return { config: API_CONFIG, global: RnxtAPIConfig }
}
