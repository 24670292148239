import { ENDPOINTS } from '@constants/endpoints.constants'

export class RoundtripsAPI {
  private Client

  constructor(Instance) {
    this.Client = Instance.Client
  }

  index = (params = {}) => {
    return this.Client.get(ENDPOINTS.ROUNDTRIPS.INDEX, params, null).then(
      response => response
    )
  }

  showCategory = (id, params = {}) => {
    return this.Client.get(ENDPOINTS.ROUNDTRIPS.SHOW_CATEGORY(id), params).then(
      res => res.data
    )
  }
}
