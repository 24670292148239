import { ENDPOINTS } from '@constants/endpoints.constants'
interface ChunkedPostsParams {
  postType: 'BUILDING_BLOCKS' | 'ROUNDTRIPS' | 'POSTS' | 'PAGES'
  pagesToFetch: number
  itemsPerPage: number
  maxPages?: number
  additionalParams?: object
  startAtPage?: number
}

export class PostTypeChunksAPI {
  private Client

  constructor(Instance) {
    this.Client = Instance.Client
  }

  index = async ({
    postType,
    pagesToFetch,
    itemsPerPage,
    maxPages = 20,
    startAtPage = 2,
    additionalParams,
  }: ChunkedPostsParams) => {
    let currentPage = startAtPage
    const promises = []

    while (currentPage <= pagesToFetch && currentPage < maxPages) {
      const params: any = {
        per_page: itemsPerPage,
        _embed: 1,
        ...additionalParams,
      }

      params.page = currentPage

      const request = this.Client.get(ENDPOINTS[postType].INDEX, params)
      promises.push(request)

      currentPage++
    }

    try {
      const chunks = await Promise.allSettled(promises)
      const fulfilled = chunks.filter(p => p.status === 'fulfilled')
      const withData = fulfilled.map((p: any) => p.value.data)
      return withData.flat()
    } catch (error) {
      console.log(error)
      return []
    }
  }
}
