interface Params {
  refreshCache?: string
}

interface NextCacheOptions {
  next: {
    cache?: 'force-cache' | 'no-store'
    revalidate?: number
  }
}

export class BridgeAPI {
  private Client

  constructor(Instance) {
    this.Client = Instance.Client
  }

  show = (params: Params = {}) => {
    let nextOptions: NextCacheOptions = { next: { revalidate: 3600 } }

    if (params?.refreshCache && params?.refreshCache === '1') {
      nextOptions = { next: { cache: 'no-store' } }
    }

    return this.Client.get(
      null,
      params,
      nextOptions,
      false, // site
      true // bridge
    ).then(response => response.data)
  }
}
