import { cache } from 'react'
import { API } from '@api'

const ITEMS_PER_PAGE = 25

export const fetchBuildingBlocks = async (
  additionalParams?: object,
  fetchAll = true
) => {
  const { data, headers } = await API.BuildingBlocks.index({
    per_page: ITEMS_PER_PAGE,
    page: 1,
    ...additionalParams,
  })

  if (!data) {
    return null
  }

  const initialResults = data
  const totalPageCount = headers?.get('x-wp-totalpages') || 1

  if (
    fetchAll &&
    totalPageCount &&
    typeof totalPageCount === 'string' &&
    parseInt(totalPageCount) > 1
  ) {
    const allOtherPosts = await API.PostTypeChunks.index({
      postType: 'BUILDING_BLOCKS',
      itemsPerPage: ITEMS_PER_PAGE,
      pagesToFetch: parseInt(totalPageCount),
      additionalParams,
    })

    const allResults = [...initialResults, ...allOtherPosts]

    return allResults
  } else {
    return initialResults
  }
}

export const buildingBlocks = cache(fetchBuildingBlocks)
